import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

const gtagFranchiseMap: Record<string, string> = {
    '5727': 'AW-16721452878',
};

const MicrositeGtagProvider = ({ children, data }: { children: React.ReactNode, data: any }): JSX.Element => {
    const franchiseNumber = data?.franchise?.franchiseNumber;

    return (
        <>
            {franchiseNumber && gtagFranchiseMap[String(franchiseNumber)] && (
                <Script
                    id={`gtm-${String(franchiseNumber)}`}
                    strategy={ScriptStrategy.idle}
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({});
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                            var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(
                            window,document,'script','dataLayer', '${gtagFranchiseMap[String(franchiseNumber)]}');
                        `,
                    }}
                />
            )}
            {children}
        </>
    );
};

export default MicrositeGtagProvider;
